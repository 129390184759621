<template>
    <CModal v-if="dailyReport"
            :title="'日報編集 <' + this.$moment(dailyReport.attributes.register_date).format('YYYY年MM月DD日') + '>'"
            color="dark"
            size="lg"
            :show.sync="isOpened">
        <div class="container-fluid p-3">
            <div class="row">
                <div class="col-12">
                    <form>
                        <div class="form">
                            <v-input v-model="form.condition_point"
                                     label="今日の調子(点数)"
                                     id="edit-condition-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('condition_point')">
                            </v-input>
                            <v-input v-model="form.body_point"
                                     label="からだ(点数)"
                                     id="edit-body-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('body_point')">
                            </v-input>
                            <v-text-area v-model="form.body"
                                         label="からだ(フリー入力)"
                                         id="edit-body-children"
                                         type="text"
                                         :errors="hasErrors('body')">
                            </v-text-area>

                            <v-input v-model="form.feeling_point"
                                     label="きもち(点数)"
                                     id="edit-feeling-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('feeling_point')">
                            </v-input>
                            <v-text-area v-model="form.feeling"
                                         label="きもち(フリー入力)"
                                         id="edit-feeling-children"
                                         type="text"
                                         :errors="hasErrors('feeling')">
                            </v-text-area>

                            <v-input v-model="form.frustration_point"
                                     label="イライラ(点数)"
                                     id="edit-frustration-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('frustration_point')">
                            </v-input>
                            <v-text-area v-model="form.frustration"
                                         label="イライラ(フリー入力)"
                                         id="edit-frustration-children"
                                         type="text"
                                         :errors="hasErrors('frustration')">
                            </v-text-area>

                            <v-input v-model="form.diet_point"
                                     label="食事(点数)"
                                     id="edit-diet-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('diet_point')">
                            </v-input>
                            <v-text-area v-model="form.diet"
                                         label="食事(フリー入力)"
                                         id="edit-diet-children"
                                         type="text"
                                         :errors="hasErrors('diet')">
                            </v-text-area>
                            <v-input v-model="form.sleep_point"
                                     label="睡眠(点数)"
                                     id="edit-sleep-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('sleep_point')">
                            </v-input>
                            <v-text-area v-model="form.sleep"
                                         label="睡眠(フリー入力)"
                                         id="edit-sleep-children"
                                         type="text"
                                         :errors="hasErrors('sleep')">
                            </v-text-area>

                            <v-input v-model="form.weather"
                                     label="天気"
                                     id="edit-weather-children"
                                     type="text"
                                     placeholder="入力必須"
                                     :errors="hasErrors('weather')">
                            </v-input>
                            <v-input v-model="form.temperature"
                                     label="気温"
                                     id="edit-temperature-children"
                                     type="text"
                                     unit="度"
                                     placeholder="入力必須"
                                     :errors="hasErrors('temperature')">
                            </v-input>
                            <v-input v-model="form.pressure"
                                     label="気圧"
                                     id="edit-pressure-children"
                                     type="text"
                                     unit="hPa"
                                     placeholder="入力必須"
                                     :errors="hasErrors('pressure')">
                            </v-input>
                            <v-input v-model="form.humidity"
                                     label="湿度"
                                     id="edit-humidity-children"
                                     type="text"
                                     unit="%"
                                     placeholder="入力必須"
                                     :errors="hasErrors('humidity')">
                            </v-input>
                            <div class="row pb-2">
                                <div class="col-3 py-2">
                                    日の出
                                </div>
                                <div class="col-7">
                                    <vue-ctk-date-time-picker
                                        id="edit-sunrize"
                                        v-model="form.sunrise"
                                        label="入力必須"
                                        :formatted="'YYYY-MM-DD HH:mm'"
                                        :format="'YYYY-MM-DD HH:mm:ss'"
                                    ></vue-ctk-date-time-picker>
                                    <div v-for="(error,index) in errors['sunrise']"
                                         class="invalid"
                                         :key="index">
                                        {{ error }}
                                    </div>
                                </div>
                                <div class="col-2">

                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="col-3 py-2">
                                    日の入
                                </div>
                                <div class="col-7">
                                    <vue-ctk-date-time-picker
                                        id="edit-sunset"
                                        v-model="form.sunset"
                                        label="入力必須"
                                        :formatted="'YYYY-MM-DD HH:mm'"
                                        :format="'YYYY-MM-DD HH:mm:ss'"
                                    ></vue-ctk-date-time-picker>
                                    <div v-for="(error,index) in errors['sunset']"
                                         class="invalid"
                                         :key="index">
                                        {{ error }}
                                    </div>
                                </div>
                                <div class="col-2">

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="updateDailyReport" :disabled="sending">更新</button>
        </template>
    </CModal>
</template>

<script>
import VSelect from "@/components/Forms/VSelect";
import VInput from "@/components/Forms/VInput";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import VTextArea from "@/components/Forms/VTextArea";

export default {
    name: "EditDailyReportChildrenModal",
    components: {VTextArea, VSelect, VInput},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            dailyReport: null,
            form: {
                register_date: '',
                condition_point: '',
                body_point: '',
                feeling_point: '',
                feeling: '',
                frustration_point: '',
                frustration: '',
                diet_point: '',
                diet: '',
                sleep_point: '',
                sleep: '',
                weather: '',
                temperature: '',
                pressure: '',
                humidity: '',
                sunrise: '',
                sunset: ''
            }
        }
    },
    methods: {
        updateDailyReport: function () {
            this.sending = true;
            window.axios.patch(this.routes.childDailyReport(this.form.id), this.form)
                .then((response) => {
                    this.showSuccessPopup("更新しました。");
                    this.$emit("daily-report-has-updated", response.data.data);
                    this.closeModal();
                })
                .catch((error) => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        openModal: function (dailyReport) {
            this.clearErrors();
            this.addToForm(dailyReport);
            this.isOpened = true;
        },
        closeModal: function () {
            this.isOpened = false;
        },
        addToForm: function (dailyReport) {
            this.dailyReport = dailyReport;
            this.form.id = dailyReport.id;
            this.form.person_id = dailyReport.attributes.person_id;
            this.form.register_date = dailyReport.attributes.register_date;
            this.form.condition_point = dailyReport.attributes.condition_point;
            this.form.body_point = dailyReport.attributes.body_point;
            this.form.body = dailyReport.attributes.body;
            this.form.feeling_point = dailyReport.attributes.feeling_point;
            this.form.feeling = dailyReport.attributes.feeling;
            this.form.frustration_point = dailyReport.attributes.frustration_point;
            this.form.frustration = dailyReport.attributes.frustration;
            this.form.diet_point = dailyReport.attributes.diet_point;
            this.form.diet = dailyReport.attributes.diet;
            this.form.sleep_point = dailyReport.attributes.sleep_point;
            this.form.sleep = dailyReport.attributes.sleep;
            this.form.weather = dailyReport.attributes.weather;
            this.form.temperature = dailyReport.attributes.temperature;
            this.form.pressure = dailyReport.attributes.pressure;
            this.form.humidity = dailyReport.attributes.humidity;
            this.form.sunrise = dailyReport.attributes.sunrise;
            this.form.sunset = dailyReport.attributes.sunset;
            this.form.weather = dailyReport.attributes.weather;
            this.form.temperature = dailyReport.attributes.temperature;
            this.form.pressure = dailyReport.attributes.pressure;
            this.form.humidity = dailyReport.attributes.humidity;
            this.form.sunrise = dailyReport.attributes.sunrise;
            this.form.sunset = dailyReport.attributes.sunset;
        },
    }
}
</script>

<style src="./EditDailyReportChildrenModal.scss" lang="scss" scoped/>

