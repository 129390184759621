<template>
    <CModal title="日報入力"
            color="dark"
            size="lg"
            :show.sync="isOpened">
        <div class="container-fluid p-3">
            <div class="row pb-2">
                <div class="col-12">
                    <h5 class="pb-3 font-weight-bold">#日付を選択</h5>
                    <v-input v-model="form.register_date"
                             label="日付"
                             id="create-register-children"
                             type="date"
                             placeholder="入力必須"
                             :errors="hasErrors('register_date')">
                    </v-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form>
                        <h5 class="pb-3 font-weight-bold">#日報を入力してください</h5>
                        <div class="form">
                            <v-input v-model="form.condition_point"
                                     label="今日の調子(点数)"
                                     id="create-condition-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('condition_point')">
                            </v-input>
                            <v-input v-model="form.body_point"
                                     label="からだ(点数)"
                                     id="create-body-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('body_point')">
                            </v-input>
                            <v-text-area v-model="form.body"
                                     label="からだ(フリー入力)"
                                     id="create-body-children"
                                     type="text"
                                     :errors="hasErrors('body')">
                            </v-text-area>

                            <v-input v-model="form.feeling_point"
                                     label="きもち(点数)"
                                     id="create-feeling-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('feeling_point')">
                            </v-input>
                            <v-text-area v-model="form.feeling"
                                     label="きもち(フリー入力)"
                                     id="create-feeling-children"
                                     type="text"
                                     :errors="hasErrors('feeling')">
                            </v-text-area>

                            <v-input v-model="form.frustration_point"
                                     label="イライラ(点数)"
                                     id="create-frustration-point-children"
                                     type="text"
                                     placeholder="入力必須"
                                     unit="点"
                                     :errors="hasErrors('frustration_point')">
                            </v-input>
                            <v-text-area v-model="form.frustration"
                                     label="イライラ(フリー入力)"
                                     id="create-frustration-children"
                                     type="text"
                                     :errors="hasErrors('frustration')">
                            </v-text-area>
                            <v-input v-model="form.diet_point"
                                     label="食事(点数)"
                                     id="create-diet-point-children"
                                     type="text"
                                     unit="点"
                                     placeholder="入力必須"
                                     :errors="hasErrors('diet_point')">
                            </v-input>
                            <v-text-area v-model="form.diet"
                                     label="食事(フリー入力)"
                                     id="create-diet-children"
                                     type="text"
                                     :errors="hasErrors('diet')">
                            </v-text-area>
                            <v-input v-model="form.sleep_point"
                                     label="睡眠(点数)"
                                     id="create-sleep-point-children"
                                     type="text"
                                     unit="点"
                                     placeholder="入力必須"
                                     :errors="hasErrors('sleep_point')">
                            </v-input>
                            <v-text-area v-model="form.sleep"
                                         label="睡眠(フリー入力)"
                                         id="create-sleep-children"
                                         type="text"
                                         :errors="hasErrors('sleep')">
                            </v-text-area>
                            <v-input v-model="form.weather"
                                     label="天気"
                                     id="create-weather-children"
                                     type="text"
                                     placeholder="入力必須"
                                     :errors="hasErrors('weather')">
                            </v-input>
                            <v-input v-model="form.temperature"
                                     label="気温"
                                     id="create-temperature-children"
                                     type="text"
                                     unit="度"
                                     placeholder="入力必須"
                                     :errors="hasErrors('temperature')">
                            </v-input>
                            <v-input v-model="form.pressure"
                                     label="気圧"
                                     id="create-pressure-children"
                                     type="text"
                                     unit="hPa"
                                     placeholder="入力必須"
                                     :errors="hasErrors('pressure')">
                            </v-input>
                            <v-input v-model="form.humidity"
                                     label="湿度"
                                     id="create-humidity-children"
                                     type="text"
                                     unit="%"
                                     placeholder="入力必須"
                                     :errors="hasErrors('humidity')">
                            </v-input>
                            <div class="row pb-2">
                                <div class="col-3 py-2">
                                    日の出
                                </div>
                                <div class="col-7">
                                    <vue-ctk-date-time-picker
                                        id="create-sunrise"
                                        v-model="form.sunrise"
                                        label="入力必須"
                                        :formatted="'YYYY-MM-DD HH:mm'"
                                        :format="'YYYY-MM-DD HH:mm:ss'"
                                    ></vue-ctk-date-time-picker>
                                    <div v-for="(error,index) in errors['sunrise']"
                                         class="invalid"
                                         :key="index">
                                        {{ error }}
                                    </div>
                                </div>
                                <div class="col-2">

                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="col-3 py-2">
                                    日の入
                                </div>
                                <div class="col-7">
                                    <vue-ctk-date-time-picker
                                        id="create-sunset"
                                        v-model="form.sunset"
                                        label="入力必須"
                                        :formatted="'YYYY-MM-DD HH:mm'"
                                        :format="'YYYY-MM-DD HH:mm:ss'"
                                    ></vue-ctk-date-time-picker>
                                    <div v-for="(error,index) in errors['sunset']"
                                         class="invalid"
                                         :key="index">
                                        {{ error }}
                                    </div>
                                </div>
                                <div class="col-2">

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="createDailyReport" :disabled="sending">登録</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VSelect from "@/components/Forms/VSelect";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import VTextArea from "@/components/Forms/VTextArea";

export default {
    name: "CreateDailyReportChildrenModal",
    components: {VTextArea, VSelect, VInput},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            weather: null,
            form: {
                person_id: this.$route.params.id,
                register_date: this.$moment().format('YYYY-MM-DD'),
                condition_point: '',
                body_point: '',
                body: '',
                feeling_point: '',
                feeling: '',
                frustration_point: '',
                frustration: '',
                diet_point: '',
                diet: '',
                sleep_point: '',
                sleep: '',
                weather: '',
                temperature: '',
                pressure: '',
                humidity: '',
                sunrise: '',
                sunset: ''
            }
        }
    },
    methods: {
        loadWeatherData: function () {
            window.axios.get(this.routes.weather)
                .then((response) => {
                    this.initWeatherForm(response.data);
                })
                .catch((error) => {
                    this.initForm();
                    this.showErrorPopup('天気の情報を<br>取得できませんでした<br><br>入力してください');
                })
        },
        createDailyReport: function () {
            this.sending = true;
            window.axios.post(this.routes.childDailyReports, this.form)
                .then((response) => {
                    this.showSuccessPopup("登録しました。");
                    this.$emit("daily-report-has-created", response.data.data);
                    this.closeModal();
                })
                .catch((error) => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        initWeatherForm: function (weather) {
            try {
                this.form.weather = weather.weather[0].description;
                this.form.temperature = this.floor(weather.temp, 1);
                this.form.pressure = this.floor(weather.pressure, 1);
                this.form.humidity = this.floor(weather.humidity, 1);
                this.form.sunrise = this.$moment(this.unixTo(weather.sunrise)).format('YYYY-MM-DD HH:mm');
                this.form.sunset = this.$moment(this.unixTo(weather.sunset)).format('YYYY-MM-DD HH:mm');
            } catch (error) {
                this.showErrorPopup('天気の情報を<br>取得できませんでした<br><br>入力してください');
                this.form.weather = '';
                this.form.temperature = '';
                this.form.pressure = '';
                this.form.humidity = '';
                this.form.sunrise = '';
                this.form.sunset = '';
            }
        },
        initForm: function () {
            this.form.register_date = this.$moment().format('YYYY-MM-DD');
            this.form.condition_point = '';
            this.form.body_point = '';
            this.form.body = '';
            this.form.feeling_point = '';
            this.form.feeling = '';
            this.form.frustration_point = '';
            this.form.frustration = '';
            this.form.diet_point = '';
            this.form.diet = '';
            this.form.sleep_point = '';
            this.form.sleep = '';
            this.form.weather = '';
            this.form.temperature = '';
            this.form.pressure = '';
            this.form.humidity = '';
            this.form.sunrise = '';
            this.form.sunset = '';
        },
        floor: function (data, digit) {
            return Math.floor(data * digit) / digit;
        },
        unixTo: function (unix) {
            return unix * 1000;
        },
        openModal: function () {
            this.isOpened = true;
            this.clearErrors();
            this.initForm();
            this.loadWeatherData();
        },
        closeModal: function () {
            this.isOpened = false;
        }
    }
}
</script>

<style scoped>

</style>
