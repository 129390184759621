<template>
    <div v-if="person && dailyReports">
        <div class="container-fluid">
            <div class="row pb-3">
                <div class="col-12">
                    <h3 class="d-inline">#日報一覧</h3>
                    <h6 class="d-inline pl-2">
                        {{ person.relationships.facility.attributes.name }} / {{ person.attributes.name }}様
                    </h6>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-10 float-left">
                    <div class="d-inline pr-2">
                        <select class="custom-select w-25" v-model="register_year" @change="loadDailyReportData">
                            <option v-for="(year,index) in years" :value="year" :key="index">{{ year }}年</option>
                        </select>
                    </div>
                    <div class="d-inline pr-2">
                        <select class="custom-select w-25" v-model="register_month" @change="loadDailyReportData">
                            <option
                                v-for="(month,index) in months"
                                :value="month"
                                :key="index">
                                {{ month }}月
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-2 float-right">
                    <button type="button"
                            class="btn btn-outline-dark float-right"
                            @click="openCreateModal">＋
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <daily-report-children-list :dailyReports="dailyReports"
                                                @daily-report-has-updated="replaceUpdatedDailyReport"
                                                @dailyReport-has-deleted="deleteDailyReport"></daily-report-children-list>
                </div>
            </div>
            <create-daily-report-children-modal @daily-report-has-created="addCreatedData" ref="createModal"></create-daily-report-children-modal>
        </div>
    </div>
</template>

<script>
import DailyReportChildrenList from "@/components/DailyReportsChildren/DailyReportChildrenList/DailyReportChildrenList";
import CreateDailyReportChildrenModal
  from "@/components/DailyReportsChildren/CreateDailyReportChildrenModal/CreateDailyReportChildrenModal";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
  name: "DailyReportsChildren",
  components: {
    CreateDailyReportChildrenModal,
    DailyReportChildrenList,
  },
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            dailyReports: null,
            person: null,
            startYear: '2020',
            addFromThisYear: 5,
            years: [],
            months: ['1','2','3','4','5','6','7','8','9','10','11','12'],
            register_year: this.$moment().format('YYYY'),
            register_month: this.$moment().format('M'),
        }
    },
    created: function () {
        this.loadDailyReportData();
        this.loadPersonData();
        this.createYearOptions();
    },
    methods: {
        loadDailyReportData: function () {
            window.axios.get(this.routes.childDailyReports,{
                params: {
                    person_id: this.$route.params.id,
                    register_date: this.createRegisterDate()
                }
            })
                .then((response) => {
                    this.dailyReports = response.data.data;
                })
                .catch((error) => {

                })
        },
        loadPersonData: function () {
            window.axios.get(this.routes.person(this.$route.params.id))
                .then((response) => {
                    this.person = response.data.data;
                })
                .catch((error) => {

                })
        },
        openCreateModal: function () {
            this.$refs['createModal'].openModal();
        },
        createYearOptions: function () {
            this.years = [];
            let start = this.$moment(this.startYear);
            let end = this.$moment().add(this.addFromThisYear,'years');

            for(let year = start; year <= end; year.add(1,'years')) {
                this.years.push(year.format('YYYY'));
            }
        },
        createRegisterDate: function () {
            return this.$moment(this.register_year + '-' + this.register_month).format('YYYY-MM-DD')
        },
        addCreatedData: function ($event) {
            if (this.checkAddCreatedDataDate($event)){
                this.dailyReports.unshift($event);
            }
        },
        checkAddCreatedDataDate: function ($event) {
            return this.$moment(this.register_year + '-' + this.register_month).format('YYYY-MM')
                === this.$moment($event.attributes.register_date).format('YYYY-MM');
        },
        replaceUpdatedDailyReport: function ($event) {
            let index = this.dailyReports.findIndex(function (dailyReport) {
                return dailyReport.id === $event.id;
            });
            this.dailyReports.splice(index, 1, $event);
        },
        deleteDailyReport: function ($event) {
            const index = this.dailyReports.findIndex(function (dailyReport) {
                return dailyReport.id === $event;
            });
            this.dailyReports.splice(index, 1);
        }
    }
}
</script>

<style scoped>

</style>
