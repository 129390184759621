<template>
    <div class="form-group row">
        <label class="col-sm-3 col-form-label"
               :for="id">{{ label }}</label>
        <div class="col-sm-7">
      <textarea rows="2"
                :class="[{'is-invalid': isInvalid},'form-control']"
                :id="id"
                :value="value"
                :disabled="disabled"
                :placeholder="placeholder"
                @input="$emit('input', $event.target.value)"></textarea>
            <div v-for="(error,index) in errors"
                 :class="[{'invalid-feedback': isInvalid}]"
                 :key="index">
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VTextArea",
    props: {
        readonly: {
            type: Boolean,
            require: false,
            default: false
        },
        disabled: {
            type: Boolean,
            require: false,
            default: false
        },
        label: {
            type: String,
            require: false,
            default: ''
        },
        id: {
            type: String,
            require: false,
            default: ''
        },
        value: {
            type: String,
            require: false,
            default: ''
        },
        placeholder: {
            type: String,
            require: false,
            default: ''
        },
        errors: {
            type: Array,
            require: true,
            default: () => ([])
        },
    },
    computed: {
        isInvalid: function () {
            return this.errors.length > 0;
        }
    }
}
</script>
