<template>
    <CModal v-if="dailyReport"
            :title="'日報編集 <' + this.$moment(dailyReport.attributes.register_date).format('YYYY年MM月DD日') + '>'"
            color="dark"
            size="lg"
            :show.sync="isOpened">
        <div class="container-fluid p-3" v-if="dailyReport">
            <div class="row">
                <div class="col-12">
                    <form>
                        <div class="form">
                            <v-input :value="dailyReport.attributes.condition_point"
                                     label="今日の調子(点数)"
                                     id="display-condition-point-children"
                                     type="text"
                                     unit="点"
                                     disabled>
                            </v-input>
                            <v-input :value="dailyReport.attributes.body_point"
                                     label="からだ(点数)"
                                     id="display-body-point-children"
                                     type="text"
                                     unit="点"
                                     disabled>
                            </v-input>
                            <v-text-area :value="dailyReport.attributes.body"
                                         label="からだ(フリー入力)"
                                         id="display-body-children"
                                         type="text"
                                         disabled>
                            </v-text-area>

                            <v-input :value="dailyReport.attributes.feeling_point"
                                     label="きもち(点数)"
                                     id="display-feeling-point-children"
                                     type="text"
                                     unit="点"
                                     disabled>
                            </v-input>
                            <v-text-area :value="dailyReport.attributes.feeling"
                                         label="きもち(フリー入力)"
                                         id="display-feeling-children"
                                         type="text"
                                         disabled>
                            </v-text-area>

                            <v-input :value="dailyReport.attributes.frustration_point"
                                     label="イライラ(点数)"
                                     id="display-frustration-point-children"
                                     type="text"
                                     unit="点"
                                     disabled>
                            </v-input>
                            <v-text-area :value="dailyReport.attributes.frustration"
                                         label="イライラ(フリー入力)"
                                         id="display-frustration-children"
                                         type="text"
                                         disabled>
                            </v-text-area>

                            <v-input :value="dailyReport.attributes.diet_point"
                                     label="食事(点数)"
                                     id="display-diet-point-children"
                                     type="text"
                                     unit="点"
                                     disabled>
                            </v-input>
                            <v-text-area :value="dailyReport.attributes.diet"
                                         label="食事(フリー入力)"
                                         id="display-diet-children"
                                         type="text"
                                         disabled>
                            </v-text-area>
                            <v-input :value="dailyReport.attributes.sleep_point"
                                     label="睡眠(点数)"
                                     id="display-sleep-point-children"
                                     type="text"
                                     unit="点"
                                     disabled>
                            </v-input>
                            <v-text-area :value="dailyReport.attributes.sleep"
                                         label="睡眠(フリー入力)"
                                         id="display-sleep-children"
                                         type="text"
                                         disabled>
                            </v-text-area>

                            <v-input :value="dailyReport.attributes.temperature"
                                     label="天気"
                                     id="display-weather-children"
                                     type="text"
                                     disabled>
                            </v-input>
                            <v-input :value="dailyReport.attributes.weather"
                                     label="気温"
                                     id="display-temperature-children"
                                     type="text"
                                     unit="度"
                                     disabled>
                            </v-input>
                            <v-input :value="dailyReport.attributes.pressure"
                                     label="気圧"
                                     id="display-pressure-children"
                                     type="text"
                                     unit="hPa"
                                     disabled>
                            </v-input>
                            <v-input :value="dailyReport.attributes.humidity"
                                     label="湿度"
                                     id="display-humidity-children"
                                     type="text"
                                     unit="%"
                                     disabled>
                            </v-input>
                            <v-input :value="dailyReport.attributes.sunrise"
                                     label="日の出"
                                     id="display-sunrise-children"
                                     type="text"
                                     disabled>
                            </v-input>
                            <v-input :value="dailyReport.attributes.sunset"
                                     label="日の入"
                                     id="display-sunset-children"
                                     type="text"
                                     disabled>
                            </v-input>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <template #footer>
            <button type="button" class="btn btn-dark" @click="isOpened = false">閉じる</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import VTextArea from "@/components/Forms/VTextArea";

export default {
    name: "DisplayDailyReportChildrenModal",
    components: {VTextArea, VInput},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            dailyReport: null
        }
    },
    methods: {
        openModal: function (dailyReport) {
            this.isOpened = true;
            this.dailyReport = dailyReport;
        }
    }
}
</script>

<style scoped>

</style>
